@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
html {
  scroll-behavior: smooth;
  font-family: "Almarai", sans-serif;
}
* {
  box-sizing: border-box;
  margin: 0;
}
body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  background: #eeeeeeba;
}
body::-webkit-scrollbar-thumb {
  background-color: #de1e35;
}
.muirtl-hyum1k-MuiToolbar-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
